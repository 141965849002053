/* .musicPlayer {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 11%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    color: rgb(126, 123, 123);
    font-family: "Lato", sans-serif;
    font-weight: 300;
    margin-left: 100px;
    z-index: 100;
} */
.track-parent {
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    /* margin-right: 80px; */
    display: flex;
    flex-direction: column;
    align-items: end;

}

.track-info {
    /* border: 2px solid red; */
    margin-right: 100px;
}


.song-name {
    text-wrap: wrap;
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    color: (132, 92, 125);
}

.artist-name {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 0;
    color: rgb(132, 92, 125);
}

.controls-progress {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    /* padding: 0px 25px;
    margin: 0px 25px; */
}

.controls {
    /* border: 2px solid rgb(95, 195, 146); */
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controls button {
    background: transparent;
    border: none;
    font-size: 1.2em;
    margin: 0 15px;
    color: rgb(95, 195, 146);
    cursor: pointer;
    transition: color 0.2s;
}

.controls button:disabled {
    color: rgb(85, 106, 96);
}

/* .controls button:hover {
    color: rgb(95, 195, 146);
} */

.progress-bar {
    margin: 20px 0px 5px 0px;
    width: 400px;
    height: 30px;
    /* border: 2px solid purple; */
}


.volume-control {
    display: flex;
    margin-right: 160px;
    align-items: center;
}

.volume-control input[type="range"] {
    width: 100px;
    -webkit-appearance: none;
    appearance: none;
    height: 5px;
    background: #888;
    border-radius: 10px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.volume-control input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: rgb(95, 195, 146);
    border-radius: 50%;
    cursor: pointer;
}

.volume-control input[type="range"]:hover {
    opacity: 1;
}

.dot5 {
    color: rgb(95, 195, 146);
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
.track-parent {
    /* border: 2px solid yellow; */
    display: none;
    display: none;
    flex-direction: column;
    /* margin-right: 80px; */
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    display: none;
}
.controls {
    /* border: 2px solid rgb(95, 195, 146); */
    /* width: 400px; */
    height: 30%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid lime; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.controls-progress {
    width: 100%;
    margin: 0%;
    height: 60%;
    /* margin-left: 7%; */
}

.volume-control {
    display: flex;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    margin: 0% auto;
    /* margin-right: 160px;
     */
     justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
}

.progress-bar {
    /* display: flex;
    align-items: center; */
    box-sizing: border-box;
    /* border: 1px solid blue; */
    width: 100%;
    height: 70%;
    margin-top:0%;
    margin-bottom: 0%;
    padding-top: 5%;
    /* border: 2px solid purple; */
}
.rc-slider-rail{
margin-top: 5%;
}
.progress-bar .rc-slider-track {
    position: absolute;
    top: 41.5%;
    left:2%;
}


/* .rc-slider-track {
    position: absolute;
    bottom: 19%;
    margin-left: 7%;
} */



}
