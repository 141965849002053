.page-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 210px;
  min-height: 100vh;
  background: linear-gradient(rgb(80, 79, 79), black);
}

.content-container {
  width: 100%;
  padding: 10px;
}

.homeText {
  height: 60px;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  font-weight: lighter;
}

.home-h3 {
  height: 60px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.homeAlbums {
  color: rgb(223, 221, 221);
  font-size: 28px;
  font-weight: 400;
  margin-left: 50px;
}

.album-cards-container {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-left: 49px;
  padding-right: 20px;
  /* background: linear-gradient(rgb(80, 79, 79), black); */
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
  max-height: 300px;
  max-width: 300px;
  border-radius: 10px;
  flex-shrink: 0;
  width: 286px;
  /* border: 2px solid red; */
}

.album-image {
  margin-top: 10px;
  /* width: 85%; */
  width: 247px;
  /* height: 90%; */
  height: 229px;
}

.card {
  max-width: 300px;
  width: calc(20% - 4px);
  margin: 0 2px;
  background: linear-gradient(black, rgb(106, 106, 106));
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  /* border: 2px solid blue; */
}

.card2 {
  max-width: 300px;
  width: calc(20% - 4px);
  margin: 0 2px;
  /* background: linear-gradient( rgb(25, 72, 55),rgb(86, 173, 130)); */
  /* background: linear-gradient( rgb(33, 23, 32), rgb(155, 102, 146) 100%); */
  background: linear-gradient(to bottom,
      rgba(16, 10, 15, 0.7) 70%,
      rgba(247, 78, 219, 0.3) 100%);
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

.card-info-container {
  display: block;
  height: 60px;
}

.album-title {
  margin-left: 20px;
  margin-bottom: 0;
  margin-top: 2px;
  /* border: 2px solid orange; */
}

.album-title1 {
  margin-left: 20px;
  margin-bottom: 0;
  margin-top: 2px;
  font-weight: bold;
  font-stretch: expanded;
  color: rgb(223, 221, 221);
  /* border: 2px solid orange; */
}

@media only screen and (max-width: 600px) {

  .homeText {
    margin-top: 0%;
    height: 15%;
    width: 100%;
    padding-bottom: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    font-size: .8em;
}


  .page-container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    /* border: 1px solid purple; */
    padding-left: 3%;
    padding-right: 3%;
    flex-direction: column;
    min-height: 100dvh;
    width: 97vw;
    /* background: linear-gradient(rgb(80, 79, 79), black); */
    background-color: black;


  }



  .content-container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    /* border: 1px solid blue; */
    margin-left: 14vw;
    width: 69%;
    flex-direction: column;
    height: 50vh;
    /* background: linear-gradient(rgb(80, 79, 79), black); */
  }

  .album-image {
    width: 70%;
    height: 70%;
    margin: 2% 0%;

  }

  .home-h3 {
    width: 100%;
    height: 7%;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0%;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77vw;
  height: 31vh;
  max-height: 300px;
  max-width: 300px;
  border-radius: 10px;
  flex-shrink: 0;
  /* border: 2px solid red; */
}

.album-cards-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* border: 1px solid orange; */
  grid-template-columns: repeat(4, 1fr);
  width: 96%;
  /* width: 81%; */
  grid-gap: 15px;
  gap: 15px;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 1%;
  margin-right: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  background: black;
  /* height: 67%; */
  justify-content: center;

  padding-bottom: 200px;
}





.sideToggleAlex {
  display: none;
  position: absolute;
  left: 0%;
  top:0%;
  z-index:9000;
  width: 58vw;
  height: 100%;
}

.menu-toggle {
  /* border: 2px solid orange; */
  background-color: black;
  font-size: 30px;
  border: none;
  margin-top: 35px;
  width: 20%;
  color: white;
  z-index: 3500;
}

.homeAlbums {
  color: rgb(223, 221, 221);
  font-size: 28px;
  font-weight: 400;
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}

}
