
.hidden {
  display: none;
}

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: black;
  padding: 10px 25px 18px 25px;
  padding-bottom: 8px;
  z-index: 1;
  height: 42px;
}


.Moodify-logo {
  width: 160px;
  height: 60px;
}

.nav-profile {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-right: 60px;
  color: white;

}

.new-album {
  text-decoration: none;
  color: white;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 18px;
  right: 110px;
  top: 30px;
  padding: 7px 12px;
}

.new-album:hover {
  cursor: pointer;
  color: rgb(95, 195, 146);
  background-color: white;
  border-radius: 20px;
  transition-delay: 0ms;
}

.new-album:active {
  border: 1px solid rgb(246, 243, 243);
  box-shadow: 0 2px 5px rgb(164, 163, 163);
  transform: translateY(2px);
  padding-top: 10px;
  padding-bottom: 10px;
} */

#profile-dropdown-loggedin {
  z-index: 50;
  width: 200px;
  max-width: 230px;
  word-wrap: break-word;
  background-color: rgb(35, 32, 32);

}

#profile-dropdown-loggedout {
  z-index: 50;
  background-color: black;
  width: 75px;
}

.start {
  color: rgb(198, 188, 193);
  background-color: transparent;
  border: rgb(53, 50, 50);
  font-size: 16px;
  padding: 5px 10px;
}

.start:hover {
  cursor: pointer;
}


.profile-dropdown {
  position: absolute;
  background-color: rgb(61, 58, 58);
  padding: 10px;
  width: 150px;
  max-width: 180px;
  top: 60px;
  right: 60px;
  border-radius: 7px;
  margin-right: 15px;
  margin-top: 10px;
  z-index: 50;
}

.profile-small-button {
  display: flex;
  justify-content: baseline;
}


.text {
  text-decoration: none;
  color: rgb(204, 180, 191);
  width: 100%;

  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.email {
  margin-top: 5px;
  margin-bottom: 8px;
  padding-bottom: 0;
  font-size: 14px;
  text-decoration: none;
  color: rgb(204, 180, 191);
}

.profile-button {
  background-color: black;
  border: black;
  border-radius: 20px;
  margin-bottom: 10px;

}

.fa-user-circle {
  margin-top: 2px;
  font-size: 28px;
  color: white;
}

.fa-bars:hover,
.fa-user-circle:hover {
  color: rgb(95, 195, 146);
}

.profile-button:hover {
  cursor: pointer;
  border: 1px solid rgb(95, 195, 146);
}

.link:hover {
  cursor: pointer;
  color: rgb(95, 195, 146);
}

.logout-button {
  border: rgb(95, 195, 146);
  background-color: rgb(95, 195, 146);
  font-size: 12px;
  border-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  height: 30px;
  color: white;
  /* margin-right: auto; */
  margin-left: auto;
  margin-top: 8px;
}

.logout-button:hover {
  cursor: pointer;
  background-color: rgb(95, 195, 146);
}

/* .dropdown {
  border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.farLeft {
  display: none;

}

.a90Wide {
  display: none;
}

@media only screen and (max-width: 600px) {
  .nav-profile {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    color: white;
    width: 36%;
    margin-right: 0%;


  }

  /* .profile-button {
    background-color: black;
    border: black;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-right: 2%;
} */

  .a90Wide {
    display: block;
    width: 64%;
    height: 100%;
  }

  .nav-container {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 8;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: black;
    padding: 10px 25px 18px 25px;
    padding-bottom: 8px;
    z-index: 1;
    padding-left: 0%;
    padding-right: 0%;
    left: auto;
    right: 0;
    bottom: auto;
    top: 0;
    height: 7%;
    border-bottom: 3px solid rgb(130, 130, 130);
}

  .Moodify-logo {
    display: none;
    width: 160px;
    height: 60px;
}

.farLeft {
  display: flex;
  flex-direction: row;
  justify-self: start;
  left: 0px;
  gap: 10%;

}

}
