#footer-container {
    background-color: rgb(0, 0, 0);
    color:rgb(141, 126, 133);
    font-weight: 100;
    position: relative;
    z-index: 0;
    min-height: 150px;
}

.creators {
    display: flex;
    justify-content: flex-end;
}

.creators1 {
    margin-right: 25px;
    margin-left: 50px;
    margin-top: 10px;
    font-weight: bold;
    font-style: italic;
}

.creator {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-top: 10px;
    position: relative;
    z-index: 2;
}

.creator-text {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.creator-link-icon {
    color: rgba(255, 255, 255, 0.465);
    text-decoration: none;
    transition: color 0.3s;
}

.creator-link-icon:hover {
    color: white;
}

.dot {
    margin-left: 30px;
    color:rgb(66, 60, 63);
}

.dot1 {
    margin-left: 15px;
    font-style: italic;
}

.year-aa {
    margin-left: 5px;
    margin-right: 0;
    font-style: italic;
    font-weight: bold;
}

.footer-icon {
    margin-left: 5px;
    margin-right: 10px;
    font-size: 19px;
    /* border: 2px solid red; */
}

.footer-icon:hover {
    cursor: pointer;
    color: white;
}

@media only screen and (max-width: 600px) {

    #footer-container {
        display: none;
        background-color: rgb(0, 0, 0);
        color:rgb(141, 126, 133);
        font-weight: 100;
        position: relative;
        z-index: 0;
        min-height: 150px;
    }
}
