
.horizontal-line {
    border-top: 1px solid rgb(141, 126, 133);
    margin: 10px 0;
    margin-left: 70px;
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;
}

.album-id-song-list {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.line-element-cross {
    margin-left: 90px;
    color: rgb(220, 198, 208);
    margin-bottom: 0;
    font-size: 20px;
}

.line-element-title {
    margin-left: 70px;
    color: rgb(220, 198, 208);
    margin-bottom: 0;
    font-size: 18px;
}

.fa-clock {
    color: rgb(220, 198, 208);
    margin-left: 840px;
    font-size: 20px;
}

.song-info2 {
    font-weight: bold;
    font-size: 18px;
}

.song-info4 {
    width: 200px;
    margin-left: 360px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-end;
}

.song-info {
    margin-left: 50px;
}

.song-menu {
    margin-left: 25px;
    background-color: rgb(96, 84, 84);
}

.song-li {
    padding-bottom: 0px;
    margin-left: 40px;
    margin-top: 0;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.song-li:hover {
    cursor: pointer;
    background-color: rgb(141, 126, 133);
    border-radius: 5px;
}

.vertical-title {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-left: 20px;

}
.song-actions-container {
    /* visibility: hidden; */
    width: 15px;
    margin-top: 30px;
}
.hideaway {
    visibility: hidden;
    width: 15px;
    margin-top: 30px;
}


.song-li:hover .song-actions-container {
    visibility: visible;
}


.song-update-button {
    background-color: rgb(74, 68, 71);
    width: 100%;
}

.dropdown1a {
    background-color: rgb(58, 54, 54);
    border-radius: 10px;
    width: 190px;
    height: 150px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    z-index: 3500;
}


.fa-trash {
    margin-right: 10px;
}

.horizontal-line1 {
    border-top: 1px solid rgb(83, 74, 78);
    margin: 10px 0;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
}


/* .play-button {
    position: relative;
    background: rgb(95, 195, 146);
    border: none;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 55px;
}

.play-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid #000;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    z-index: 1000;
} */

.menu-icon1 {
    margin-right: 8px;
}

.dropdown6 {
    /* border: 2px solid red; */
    background-color: rgb(21, 21, 21);
    /* margin-right: 200px; */

}




.album-dropdown {
    /* border: 2px solid red; */
    z-index: 1;

}

.start:hover {
    cursor: pointer;
    color: rgb(95, 195, 146);
}

@media only screen and (max-width: 600px) {

    .album-id-song-list {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: -196%;
        width: 50%;
    }

    /* .song-li {
        width: 297%;
        padding-bottom: 0px;
        margin-left: -210%;
        margin-top: 28%;
        margin-bottom: 58%;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
    } */
    .album-id-song-list {
        margin-left: 0%;
    }
    .separateBottom{
        padding: 0% 0%;
        margin: 0% 0%;
    }
    .song-li {
        box-sizing: border-box;
        /* border:1px solid green; */
        width: 96vw;
        padding-bottom: 0px;
        margin: 0% 0%;
        padding: 0% 0%;
        margin-top: 11%;
        margin-bottom: 11%;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .song-info1 {
        width: 10%;
    }
    .vertical-title {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-left: 20px;
    }

    .song-info4 {
        width: 200px;
        margin-left: 14%;
        margin-bottom: 30px;
        display: flex;
        align-items: flex-end;
    }

    .separateBottom > :last-child {
        margin-bottom: 226px;
    }

    .song-info2 {
        font-weight: bold;
        font-size: .9em;
        max-width: 100%;
    }

    .song-info3 {
        margin-top:9%;
        font-size: .7em
    }

    .horizontal-line {
        border-top: 1px solid rgb(141, 126, 133);
        margin: 10px 0;
        /* margin-left: 70px; */
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .dropdown1a {
        background-color: rgb(58, 54, 54);
        border-radius: 10px;
        width: 60vw;
        height: 27dvh;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
        z-index: 3500;
        right: 0%;
    }

    .fa-clock {
        display: none;
    }



}
