
.album-page-container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: 210px;
    min-height: 100vh;
    background: linear-gradient(rgb(108, 94, 101), rgb(54, 47, 51) 80%);
    margin-left: 50px;
}

.album-id-top-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 370px;
    background: linear-gradient(to bottom, rgb(215, 191, 202), rgb(108, 94, 101) 90%);
}

.album-id-cover-img {
    margin-right: 40px;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 30px;
    width: 250px;
    height: 250px;
    box-shadow: 2px 2px 22px rgb(59, 51, 51);
}

.amount-songs {
    height: 10px;
    font-size: 12px;
    color: rgb(223, 221, 221);
    font-weight: 100;
}

.album-title-page1 {
    /* border: 2px solid red; */
    font-size: 62px;
    margin-top: 95px;
    margin-bottom: 10px;
    font-weight: bold;
    padding-left: 0;
}

.info-album-p {

    margin-top: 80px;
    margin-bottom: 0;
}

#album-id-info {

    margin-top: 0;
}

.album-release-info {
    font-size: 16px;
    /* margin-bottom: px; */
    margin-top: 20px;
}

.album-release-info1 {
    font-size: 14px;
    margin-top: 0;

}

.album-id-functions-4 {
    /* border: 2px solid red; */
    height: 100px;
    display: flex;
    align-items: center;
    margin-left: 200px;
}

.new-album {
    margin-left: 50px;
}


.album-update-dropdown1 {
    z-index: 3000;
}

.album-big-dots {

    font-size: 50px;
    color: rgb(166, 149, 157);
    margin-bottom: 300px;
    margin-left: 30px;
}

.album-dots-container {
    margin-bottom: 30px;
}

.dropdown2 {
    /* border: 2px solid red; */
  /* background-color: rgb(26, 24, 25); */
  background-color: rgb(44, 41, 41);
  border-radius: 10px;
  width: 150px;
  height: 140px;
  position: absolute;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-left: 20px;
}


.menu-icon {
    margin-right: 10px;
    margin-left: 5px;
}


.horizontal-line2 {
    border-top: 1px solid rgb(83, 74, 78);
    margin: 10px 0;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
}


.playlist-id-cover-img {
    /* border: 2px solid red; */
    margin-right: 40px;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 30px;
    width: 250px;
    height: 250px;
    /* box-shadow: 2px 2px 22px rgb(59, 51, 51); */
}


.play-button-playlist {
    position: relative;
    background: rgb(95, 195, 146);
    border: none;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 15px 0px 0px 105px;
}

.fa-play {
    font-size: 43px;
    margin-left: 10px;
}

.fa-pause {
    font-size: 45px;
}


.big-play-diabled-playlist {
    background: rgb(28, 51, 39);
}



@media only screen and (max-width: 600px) {
.album-id-top-info {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    /* border: 5px solid orange; */
    margin-left: 0%;
    padding-left: 0%;
    width: 91vw;
    height: 10dvh;
    margin-top: 41%;
    height: 370px;
    background: linear-gradient(to bottom, rgb(215, 191, 202), rgb(108, 94, 101) 90%);
}
#album-id-info-words {
    display: flex;

    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 2% 10%;
    text-align: center;

}
.album-id-cover-img {
    margin: auto 1%;
    width: 50%;
    height: 67%;
    box-shadow: 2px 2px 22px rgb(59, 51, 51);
}

.album-title-page {
    font-size: 1em;
}

.album-release-info {
    font-size: .8em;
    margin-top: 20px;
}

.album-update-dropdown1 {
    display: block;
    z-index: 3000;
    width: 70%;
}

.info-album-p {
    margin-top: 0%;
    margin-bottom: 0;
}

.playlist-id-cover-img {
    margin: auto 1%;
    width: 50%;
    height: 67%;
    box-shadow: 2px 2px 22px rgb(59, 51, 51);
}
}
