.errors-ul {
    color:red;
    font-size:small;
}


.file-album-cover {
    color: rgb(95, 195, 146);
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 40px;
}

.signup-container6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    height: 720px;
    background-color: rgb(21, 21, 21);
    padding: 5px;
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
    z-index: 5000;

}

.cac-1 {
    margin-top: 0;
}

.cac-2 {
    margin-top: 0;
    height: 60px;
    width: 60px;
    margin-left: 40px;
}

.custom-file-input {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background: rgb(21, 21, 21);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0;
    color: rgb(119, 239, 6);
    border: none;
    height: 200px;

}

.new-album-image {
    height: 150px;
    width: 150px;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 8px;
}

.signup-form5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    padding-top: 10px;
}

.signup-form5 input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
    margin-top: 3px;
}

signup-form5 input::placeholder {
    color: rgb(150, 149, 149);
    align-self: center;
}

.new-h5 {
    font-size: 24px;
    font-weight: bold;
}

.login-label {
    margin-bottom: 20px;
    color: rgb(141, 126, 133);
}

.signup-button10 {
    padding: 16px 20px;
    /* margin-top: 15px; */
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 80%;
    border-radius: 20px;
}

.signup-button10:hover {
    transform: scale(1.05);
    background-color: rgb(95, 195, 146);
}

input {
    text-align: center;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin: 0px;
    margin-right: 5px;
}


.not-loading {
    display: none;
}


.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 670px;
}

.photo-container {
    position: relative;
    height: 200px;
    width: 200px;
}

#drop-area {
    /* padding: 20px; */
    text-align: center;
    cursor: pointer;
    color: white;
}

#drop-area.dragging {
    border-color: #28a745;
}

.choose-file input {
    display: none;
}

.upload-text {
    border: 2px dashed #ddd;
    padding: 10px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

.album-form-errors {
    margin: 0px;
    padding: 0px;

}

@media only screen and (max-width: 600px) {
.signup-container6 {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60dvh;
    background-color: rgb(21, 21, 21);
    padding: 5px;
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
    z-index: 5000;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    position: static;

}

.new-h5 {
    font-size: 1em;
    font-weight: bold;
    height: 0%;
    max-height: 10%;
    padding: 0%;
    margin: 0%;

}

.signup-form5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

}

.login-label {
    height: 16%;
    color: rgb(141, 126, 133);
    margin-bottom: 1%;
}

.custom-file-input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    /* overflow: hidden; */
    background: rgb(21, 21, 21);
    /* padding: 10px; */
    border-radius: 5px;
    cursor: pointer;
    color: rgb(119, 239, 6);
    border: none;
    height: 38%;
    width: 100%;
    padding: 0px 0px;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
}

.justLog {
    height: 27%;
    color: rgb(141, 126, 133);
    margin-bottom: 1%;
}


.file-album-cover {
    display: block;
    width: 100%;
    padding: 0% 0%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0%;
    height: 19%;
    text-align: center;
    box-sizing: border-box;
    /* border: 1px solid green; */
    font-size: 1em;

}

.photo-container {
    position: relative;
    height: 66%;
    width: 100%;
    margin-top: 0%;
    margin-bottom: 0%;
    box-sizing: border-box;
    /* border: 5px solid purple; */
}

#drop-area {
    /* padding: 20px; */
    text-align: center;
    cursor: pointer;
    color: white;
    width: 100%;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    height: 100%;
    display: flex;
    justify-content: center;
}

.upload-text {
    border: 2px dashed #ddd;
    padding: 0%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    font-size: .6em;
    height: 99%;
    width: 28%;
}

.specialOps {
    position: relative;
    z-index:12000;
    width: 18%;
    height: 65%;
}

.signup-button10 {
    padding: 2% 2%;
    margin-top: 2%;
    margin-bottom: 0%;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 80%;
    border-radius: 20px;
}
.signup-button20 {
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 0%;
    padding-right: 4%;
    margin-top: 0%;
    margin-bottom: 0%;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 23vw;
    border-radius: 20px;
}

.cac-1 {
    margin-top: 0;
    margin-bottom: 0%;
    display: block;
    text-align: center;
    width: 100%;
}
.cac-2 {
    margin-left: 0%;
}

.mobileMode3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid gray;
    text-align: center;
    width: 92vw;
    height: 100%;
}

.new-album-main-container {
    width: 100%;
    height: 60dvh;
}



}
