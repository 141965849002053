#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}

@media only screen and (max-width: 600px) {

  #modal-content {
    position: absolute;
    background-color:transparent;
    width: 50%;
    margin-left: 0%;

    /* top:24%; */
    top:7%;
    bottom: 25dvh;

  }

  #modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    box-sizing: border-box;
    /* border: 1px solid green; */
    width: 96vw;
  }

  #modal-background {
    position: fixed;
    top: 2%;
    right: 1%;
    left: 1%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 99%;
    height: 93%;
}

}
