.signup-container5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 480px;
    background-color: rgb(0, 0, 0);
    padding: 5px;
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
    z-index: 5000;
}


.new-h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.signup-button {
    margin-top: 30px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 18px;
    /* font-weight: bold; */
    cursor: pointer;
    width: 80%;
    border-radius: 20px;
}


.signup-button:hover {
    transform: scale(1.05);
}

input {
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .signup-container5 {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60dvh;
        background-color: rgb(21, 21, 21);
        padding: 5px;
        box-shadow: 2px 2px 22px rgb(70, 70, 70);
        z-index: 5000;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        position: relative;
    }


    .txt1 {
        display: flex;
        justify-content: center;
        text-align: center;
        color: rgb(194, 176, 184);
        font-size: 16px;
    }

    .new-h1 {
        font-size: 1.4em;
        font-weight: bold;
        margin-top: 0%;
        margin-bottom: 3%;
    }


    }
