body {
  background: linear-gradient(rgb(80, 79, 79), black);
  margin: 0;
  padding: 0;

  overflow-x: hidden;
  width: 100%;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

/* .main_window_container {
  height: 79%;
  width: 100%;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
} */

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 89%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: black;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  flex-grow: 1;
  z-index: 50;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #5e0a75 black;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: #5e0a75;
  border-radius: 10px;
  border: 3px none black;
}

.container {
  height: 100%;
}

.musicPlayer {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 11%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  color: rgb(126, 123, 123);
  font-family: "Lato", sans-serif;

  z-index: 100;
  padding: 0px 0px 0px 200px;
}

.all-validation-errors {
  color: red;
  font-size: smaller;
  text-align: center;
}

@media only screen and (max-width: 600px) {

  html {
    background-color: black;
  }
  body {
    background-color: black;
  }

  .container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    /* border: 1px solid red; */
  }
  .side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    /* width: 20%; */
    height: 89%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: black;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    flex-grow: 1;
    z-index: 50;
  }

  .main_window_container {
    background-color: black;
    box-sizing: border-box;
    /* border: 1px solid red; */
    margin-left: -34%;
    height: 81.5dvh;
    margin-top: 21%;
    width: 99vw;

  }

  .musicPlayer {
    position: fixed;
    box-sizing: border-box;
    /* border: 1px solid brown; */
    bottom: 0px;
    width: 96.5vw;
    height: 20%;

    display: block;
    /* flex-direction: column;
    flex-wrap: wrap; */

    border-top: 3px solid rgb(130, 130, 130);
    flex-direction: column;
    flex-wrap: wrap;
    background-color: black;
    color: rgb(126, 123, 123);
    font-family: "Lato", sans-serif;
    z-index: 100;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px;
}
}
