.signup-container7 {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 650px;
    background-color: rgb(21, 21, 21);
    padding: 5px;
    /* box-shadow: 2px 2px 22px rgb(107, 106, 106); */
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
    /* margin-left: 200px; */
}


#modal-content {
    /* border: 2px solid red; */
    margin-left: 200px;
}

.new-h2 {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    /* gap: 3px; */
    padding-top: 10px;
}

.signup-form input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
    margin-top: 3px;
}

.color {
    color: rgb(161, 161, 161);;
}



.not-loading2 {
    display: none;
}


.is-loading2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 580px;
}


.song-form-errors {
    margin: 0px;
}

.no-song-errors {
    padding: 100px;
}



.signup-button-song {
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}

.signup-button-song:hover {
    transform: scale(1.05);
    background-color: rgb(95, 195, 146);
}


.custom-file-input2 {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background: rgb(21, 21, 21);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0;
    color: rgb(119, 239, 6);
    border: none;
    height: 100px;

}


@media only screen and (max-width: 600px) {
    #modal-content {
        /* border: 2px solid red; */
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: 98%;
        box-sizing: border-box;
        /* border: 1px solid yellow; */
        height: 59dvh;
        margin-left: auto;
        margin-right: auto;
        position: relative;

    }

    .signup-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        gap: 7%;
        padding-top: 10px;
        height: 100%;
    }



}
