
.album-detail-page-container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: 210px;
    min-height: 100vh;
    background: linear-gradient(rgb(108, 94, 101), rgb(54, 47, 51) 80%);
    /* margin-left: 50px; */
}


.album-id-top-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 370px;
    background: linear-gradient(to bottom, rgb(215, 191, 202), rgb(108, 94, 101) 90%);
}

.album-id-cover-img {
    margin-right: 40px;
    margin-left: 100px;
    margin-top: 80px;
    margin-bottom: 30px;
    width: 250px;
    height: 250px;
    box-shadow: 2px 2px 22px rgb(59, 51, 51);
}

.amount-songs {
    height: 10px;
    font-size: 12px;
    color: rgb(223, 221, 221);
    font-weight: 100;
}

.album-title-page {
    font-size: 62px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    padding-left: 0;
}

.info-album-p {
    margin-top: 80px;
    margin-bottom: 0;
}

#album-id-info {

    margin-top: 0;
}

.album-release-info {
    font-size: 16px;
    margin-bottom: 10px;
}

.album-id-functions-3 {
    height: 100px;
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.new-album {
    margin-left: 50px;
}


.album-update-dropdown1 {
    z-index: 3000;
}

.album-big-dots {

    font-size: 50px;
    color: rgb(166, 149, 157);
    margin-bottom: 300px;
    margin-left: 30px;
    z-index: 3000;
}

.album-dots-container {
     /* border: 2px solid red; */
    margin-bottom: 30px;
    z-index: 10;
}

.dropdown5 {
    /* border: 2px solid red; */
  background-color: rgb(44, 41, 41);
  border-radius: 10px;
  width: 150px;
  height: 140px;
  position: absolute;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-right: 100px;
  /* z-index: 1000; */
}


.menu-icon {
    margin-right: 10px;
    margin-left: 5px;
}


.horizontal-line2 {
    border-top: 1px solid rgb(83, 74, 78);
    margin: 10px 0;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
}


.play-button-album {
    position: relative;
    background: rgb(95, 195, 146);
    border: none;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 55px;
}

.fa-play {
    color: rgb(61, 61, 61);
}

.fa-pause {
    color: rgb(61, 61, 61);
}

.big-play-diabled-album {
    background: rgb(28, 51, 39);
}

@media only screen and (max-width: 600px) {
    .album-detail-page-container {
        box-sizing: border-box;
        /* border: 5px solid purple; */
        display: flex;
        position: relative;
        flex-direction: column;
        height: 20vh;
        width: 97vw;
        background: black;
        padding-left: 2%;

    }

    /* .album-detail-page-container > :last-child {
        margin-bottom: 20%;
    } */



.album-id-cover-img {
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 80%;
    height: 50%;
    box-shadow: 2px 2px 22px rgb(59, 51, 51);
}

.album-id-functions-3 {
    display: flex;
    box-sizing: border-box;
    /* border: 1px solid white; */
    flex-direction: row;
    width: 96vw;
    margin: 0% auto;
}
element.style {
}
.play-button-album {
    position: relative;
    background: rgb(95, 195, 146);
    border: none;
    border-radius: 50%;
    width: 30%;;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 2%;
}

}

@media only screen and (max-width: 375px) {
    .album-id-functions-3 {
        display: flex;
        box-sizing: border-box;
        border: 1px solid white;
        flex-direction: row;
        width: 96vw;
        margin: 0% auto;
        margin-left: calc(50% - 215%);

    }

}
