.signup-error {
    color:red;
    font-size: smaller;
    text-align: center;
}


/* .signup-form li {
    margin-top: 1px;
    color:red;
    font-size:small;
    text-align: center;
} */


.new-h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}


.signup-container3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 550px;
    background-color: rgb(21, 21, 21);
    padding: 5px;
    /* box-shadow: 2px 2px 22px rgb(107, 106, 106); */
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
}


.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    /* gap: 3px; */
    padding-top: 10px;
}


.signup-form input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
    margin-top: 3px;
}


signup-form input::placeholder {
    color: rgb(150, 149, 149);
    align-self: center;
}

input {
    text-align: center;
  }

.signup-button {
    margin-top: 30px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}


.signup-button:hover {
    transform: scale(1.05);
}


.sign-up-link a {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
}


.sign-up-link a:hover {
    color: rgb(95, 195, 146);
}



.not-loading1 {
    display: none;
}


.is-loading1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 500px;
}


.playist-form-errors {
    margin:0px;
    padding: 0px;
}

@media only screen and (max-width: 600px) {
.signup-container3{
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60dvh;
        background-color: rgb(21, 21, 21);
        padding: 5px;
        box-shadow: 2px 2px 22px rgb(70, 70, 70);
        z-index: 5000;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        position: static;
    }



}
