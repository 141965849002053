.signup-error {
    color:red;
    font-size: smaller;
    text-align: center;
}


.signup-form li {
    margin-top: 1px;
    color:red;
    font-size:small;
    text-align: center;
}


.new-h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}


.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    height: 580px;
    background-color: rgb(21, 21, 21);
    padding: 5px;
    box-shadow: 2px 2px 22px rgb(70, 70, 70);
}


.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    /* gap: 3px; */
    padding-top: 10px;
}


.signup-form input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
    margin-top: 3px;
}


signup-form input::placeholder {
    color: rgb(150, 149, 149);
    align-self: center;
}

input {
    text-align: center;
  }

.signup-button {
    margin-top: 30px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(95, 195, 146);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}


.signup-button:hover {
    transform: scale(1.05);
}


.sign-up-link a {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
}


.sign-up-link a:hover {
    color: rgb(95, 195, 146);
}
