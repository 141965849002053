.logo-container {
    text-align: center;
    margin: 20px 0;
}

.Moodify-logo {
    width: 210px;
    height: 100px;
}

.sideToggleAlex {
    width: 100%;
    height: 73%;
}

.menu-toggle {
    /* border: 2px solid orange; */
    background-color: black;
    font-size: 30px;
    border: none;
    margin-top: 35px;
    width: 210px;
    color: white;
    z-index: 3500;
    width: 100%;
    height: 5%;
}

.ul-container {

    width: 180px;
    list-style-type: none;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 13.5%;
}

li {
    padding: 8px;
}

a {
    text-decoration: none;
    font-size: 18px;
    color: rgb(198, 196, 196);
}

a:hover {
    cursor: pointer;
}

.fa-list {
    margin-right: 10px;
}

.fa-music {
    margin-right: 13px;
}

.album-big-dots1 {
    /* border: 2px solid red; */
    font-size: 24px;
}

.fa-heart {
    margin-right: 10px;
}

.loved-songs:hover {
    color: rgb(95, 195, 146);
    cursor: pointer;
}

.li1 {
    /* border: 2px solid green; */
    font-size: 16px;
    list-style: none;
    color: rgb(141, 129, 135);

}

.li1:hover {
    cursor: pointer;
    color: rgb(95, 195, 146);
}

.playlist-heather {
    /* border: 2px solid red; */
    height: 20px;
    color: rgb(198, 196, 196);
    font-size: 17px;

}

.album-update-dropdown1 {
    z-index: 3000;
}

.album-big-dots2 {
    /* border: 2px solid red; */
    font-size: 18px;
    /* color: rgb(166, 149, 157); */
    color: rgb(95, 195, 146);
    margin-bottom: 300px;
    z-index: 3000;
}

.album-big-dots2:hover {
    cursor: pointer;
    /* color: rgb(95, 195, 146); */
    color: rgb(166, 149, 157);
}

.album-dots-container1 {
    margin-bottom: 30px;
}

.dropdown3 {
    /* border: 2px solid red; */
    /* background-color: rgb(26, 24, 25); */
    background-color: rgb(44, 41, 41);
    border-radius: 10px;
    width: 150px;
    height: 140px;
    position: absolute;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin-left: 20px;
}


.menu-icon {
    margin-right: 10px;
    margin-left: 5px;
}


.horizontal-line2 {
    border-top: 1px solid rgb(83, 74, 78);
    margin: 10px 0;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.horizontal-line4 {
    border-top: 1px solid rgb(51, 45, 48);
    width: 90%;
    margin-top: 20px;
    margin-bottom: 5px;
}




.playlist-menu-container {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: baseline;
    align-items: normal;
    height: 30px;
}

.side-login {
    margin-top: 15px;
    margin-left: 20px;
    padding: 12px 14px;
    border: none;
    border-radius: 4px;
    /* background-color: rgb(95, 195, 146); */
    background-color: rgb(132, 92, 125);
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 80%;
    border-radius: 20px;
    /* text-shadow: 1px 1px 2px rgba(114, 113, 113, 0.5);; */
}


.profile-small-button1 {
    /* border: 2px solid red; */

    margin-top: 20px;
    margin-left: 40px;
    background-color: rgb(61, 58, 58);
    width: 150px;
    border-radius: 5px;
}


.dropdown8 {
    background-color: rgb(44, 41, 41);
    border-radius: 10px;
    width: 180px;
    height: 70px;
    position: absolute;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin-left: 20px;
    top: 28px;
    left: 3px;
}

.album-update-dropdown13 {
    position: relative;
}

.playlist-container {
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80%;


}

.toggleMenu {
    display: none;

}

@media only screen and (max-width: 600px) {
    .Moodify-logo {
        width: 100%;
        height: 89%;
    }

    .side-login {
        margin-top: 15px;
        margin-left: 0px;
        padding: 12px 14px;
        border: none;
        border-radius: 4px;
        /* background-color: rgb(95, 195, 146); */
        background-color: rgb(132, 92, 125);
        color: white;
        font-size: 9px;
        font-weight: bold;
        cursor: pointer;
        width: 80%;
        border-radius: 20px;
        display: none;
        /* text-shadow: 1px 1px 2px rgba(114, 113, 113, 0.5);; */
    }

    .toggleMenu {
        display: block;
        box-sizing: border-box;

        width: 100%;
        height: 6%;
        margin-left:4%;
        margin-top:4%;

    }
    .playlist-container {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 65%;
        z-index: 8999;
        padding-left: 5%;
    }


.menu-toggle {
    /* border: 2px solid orange; */
    background-color: black;
    font-size: 1.5em;
    box-sizing: border-box;
    border: none;
    margin-top: 0%;
    width: 100%;
    height: 10%;
    color: white;
    z-index: 3500;
}

.ul-container {
    width: 100%;
    height: 12%;
    list-style-type: none;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0%;
    padding-left: 0%;
}

.li1 {
    /* border: 2px solid green; */
    font-size: .8em;
    list-style: none;
    color: rgb(141, 129, 135);
}

.sideToggleAlex {
    display: none;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 9000;
    width: 58vw;
    height: 100%;
}

}
